@import 'src/utils/utils';

.cards {
  @include zero;
  display: grid;
  padding-right: 16px;
  padding-left: 16px;
  row-gap: 20px;

  @media(min-width: $md) {
    grid-template-columns: 1fr 1fr;
      column-gap: 20px;
  }
  @media(min-width: $lg) {
    padding: 0;
  }
  @media(min-width: $xl) {
    grid-template-columns: 1fr 1fr 1fr;
  }
    @media(min-width: $xxl) {
      @include box;
  }

  &__card {
    @include zero;
    position: relative;
    background-color: $Main-2;
    border-radius: 18px;
    padding-top: 18px;
    padding-right: 18px;
    padding-bottom: 18px;
    padding-left: 15px;
    overflow: hidden;

    @media(min-width: $xxl) {
      padding-top: 20px;
      padding-right: 30px;
      padding-bottom: 10px;
      padding-left: 30px;
    }

    &:before {
      position: absolute;
      width: 38px;
      height: 38px;
      top: -19px;
      left: -19px;
      border-radius: 50%;
      background-color: $Alternative-10;
      content: '';

      @media(min-width: $xxl) {
        width: 51px;
        height: 51px;
        top: -22px;
        left: -22px;
      }
    }
  }

  &__point {
    @include zero;
    @include Text-12-reg;
    color: $Main-1;

    @media(min-width: $xxl) {
      margin-bottom: 10px;
    }
  }

  &__name {
    @include zero;
    @include Text-16-bold;
    color: $Main-1;
    margin-bottom: 15px;

    @media(min-width: $xxl) {
      @include Text-18-bold;
    }
  }

  &__feature {
    @include zero; 
    margin-bottom: 15px;

    @media(min-width: $xxl) {
      margin-bottom: 20px;
    }
  }

  &__number {
    @include zero;
    @include Head-25-bold;
    font-family: $verdana;
    color: $Main-1;

    @media(min-width: $xxl) {
      font-size: 30px;
      line-height: normal;
    }
  }

  &__text {
    @include zero;
    @include Text-14-reg;
    color: $Main-1;
  }
}